<template>
  <MainLayout
    bg-color="white"
    no-content-padding
    scroll-toolbar
  >
    <template #toolbar>
      <div class="relative flex items-center justify-between px-5 py-3.5 border-b border-border-normal">
        <div class="flex items-center">
          <button
            class="p-1 pr-2 hover:bg-background-normal rounded-md flex items-center gap-2"
            @click="navToLensView"
          >
            <ChevronIcon class="transform rotate-90" />
            <img
              src="../../assets/icons/lensIcon.png"
              alt="Lens icon"
              class="w-8 h-8 rounded-md"
            >
            <BaseText
              type="label"
              size="sm"
              class="text-neutral-900 font-medium"
            >
              Lens
            </BaseText>
          </button>

          <!-- Gap Line -->
          <div class="w-px h-5 bg-border-normal rounded-sm mx-1" />

          <div class="ml-3 flex items-center gap-2">
            <div
              v-if="loading.lenses"
              class="h-5 w-16 skeleton rounded-md"
            />
            <BaseDropdownRouteSwitcher
              v-else-if="lensRoutes.length > 0"
              :current-route="currentLensRoute"
              :routes="lensRoutes"
            >
              <template #current-icon="{ route }">
                <div class="mr-2 w-5 h-5 flex-shrink-0">
                  <img
                    v-if="currentLensRoute.avatar"
                    :src="currentLensRoute.avatar"
                    class="w-5 h-5 rounded-md"
                    alt="Lens avatar"
                  >
                  <div
                    v-else
                    class="w-5 h-5 bg-neutral-400 flex items-center justify-center text-background-hover"
                    style="border-radius: 4.5px; font-size: 10px;"
                  >
                    {{ currentLensRoute.initials }}
                  </div>
                </div>
              </template>
              <template #current-icon-expanded>
                <div class="mr-2 w-5 h-5 flex-shrink-0">
                  <img
                    v-if="currentLensRoute.avatar"
                    :src="currentLensRoute.avatar"
                    class="w-5 h-5 rounded-md"
                    alt="Lens avatar"
                  >
                  <div
                    v-else
                    class="w-5 h-5 bg-neutral-400 flex items-center justify-center text-background-hover"
                    style="border-radius: 4.5px; font-size: 10px;"
                  >
                    {{ currentLensRoute.initials }}
                  </div>
                </div>
              </template>
              <template #route-icon="{ route }">
                <div class="mr-2 w-5 h-5 flex-shrink-0">
                  <img
                    v-if="route.avatar"
                    :src="route.avatar"
                    class="w-5 h-5 rounded-md"
                    alt="Lens avatar"
                  >
                  <div
                    v-else
                    class="w-5 h-5 rounded-md bg-neutral-400 flex items-center justify-center text-background-hover"
                    style="border-radius: 4.5px; font-size: 10px;"
                  >
                    {{ route.initials }}
                  </div>
                </div>
              </template>
            </BaseDropdownRouteSwitcher>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <!-- TODO @sam: extract into a component when design is finalized -->
      <div class="px-3 py-2">
        <div class="px-2.5 rounded-lg border border-border-normal">
          <div class="flex items-center justify-between px-5 py-4">
            <div class="flex items-center gap-3">
              <BarGraphIcon />
              <BaseText
                type="heading"
                size="sm"
                class="text-neutral-900 font-medium"
              >
                Reports
              </BaseText>
            </div>
            <div>
              <BaseButton
                outlined
                type="primary"
                size="sm"
                @click="show.createReportModal = true"
              >
                Create Report
              </BaseButton>
            </div>
          </div>
          <!-- Report list table -->
          <div>
            <div class="grid grid-cols-12 bg-background-disabled rounded-md">
              <div
                v-for="(header, index) in headers"
                :key="index"
                :class="`col-span-${header.span} px-2 py-3`"
              >
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  {{ header.label }}
                </BaseText>
              </div>
            </div>
            <div v-if="lensReports.length> 0">
              <div
                v-for="report in lensReports"
                :key="report.id"
                class="grid grid-cols-12 text-text-muted cursor-pointer hover:bg-neutral-10"
                @click="navToReport(report.id)"
              >
                <div class="px-2 py-3 col-span-4">
                  <BaseText
                    type="label"
                    size="sm"
                  >
                    {{ report.name }}
                  </BaseText>
                </div>
                <div class="px-2 py-3 col-span-4">
                  <BaseText
                    type="label"
                    size="sm"
                  >
                    {{ report.user_id }}
                  </BaseText>
                </div>
                <div class="px-2 py-3 col-span-3">
                  <BaseText
                    type="label"
                    size="sm"
                  >
                    {{ formatDate(report.updated_at ?? report.created_at) }}
                  </BaseText>
                </div>
                <div class="col-span-1 justify-self-end self-center mr-2">
                  <button
                    v-on-clickaway="() => { expandedReportOptions = null }"
                    class="relative option-menu-button p-0.5 rounded-md z-20"
                    :class="expandedReportOptions === report.id ? 'active' : 'inactive'"
                    @click.stop.prevent="expandedReportOptions = expandedReportOptions === report.id ? null : report.id"
                  >
                    <KebabMenuIcon />
                    <transition name="option">
                      <div
                        v-if="expandedReportOptions === report.id"
                        class="report-options flex flex-col gap-0.5 p-1 rounded-lg bg-white"
                      >
                        <button
                          class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
                        >
                          <ChangeFolderIcon class="transition-colors text-icon-normal group-hover:text-icon-muted" />
                          <BaseText
                            type="body"
                            size="sm"
                            class="transition-colors text-text-normal group-hover:text-text-muted"
                          >
                            Move
                          </BaseText>
                        </button>
                        <button
                          class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
                          @click="handleDeleteModalView(report.id)"
                        >
                          <DeleteIcon class-name="transition-colors text-icon-normal group-hover:text-icon-muted" />
                          <BaseText
                            type="body"
                            size="sm"
                            class="transition-colors text-text-normal group-hover:text-text-muted"
                          >
                            Remove
                          </BaseText>
                        </button>
                      </div>
                    </transition>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modals -->
      <NewReportModal
        v-if="show.createReportModal"
        :lens-id="lensId"
        @close="show.createReportModal = false"
      />
      <BaseConfirmDeleteModal
        v-if="show.deleteReportModal"
        confirm-text="Delete report"
        delete-disclaimer="Are you sure you want to delete this report? This action cannot be undone."
        :loading="loading.delete"
        @close="handleDeleteModalView"
        @confirm="handleReportDelete"
      />
    </template>
  </MainLayout>
</template>

<script>
import LensAPI from '@/api/lens'
import MainLayout from '../../layouts/MainLayout.vue'

import { mixin as clickaway } from 'vue-clickaway2'
// Components
import NewReportModal from '../../components/lens/NewReportModal.vue'
import BaseDropdownRouteSwitcher from '../../components/globals/BaseDropdownRouteSwitcher.vue'
// Icons
import KebabMenuIcon from '../../components/globals/Icons/KebabMenuIcon.vue'
import ChangeFolderIcon from '../../components/globals/Icons/ChangeFolderIcon.vue'
import DeleteIcon from '../../components/globals/Icons/DeleteIcon.vue'
import BaseConfirmDeleteModal from '../../components/globals/BaseConfirmDeleteModal.vue'
import BarGraphIcon from '../../components/globals/Icons/LensIcons/graphs/BarGraphIcon.vue'
import ChevronIcon from '../../components/globals/Icons/ChevronIcon.vue'

export default {
  name: 'LensDashboardView',
  components: {
    MainLayout,
    NewReportModal,
    BaseConfirmDeleteModal,
    BaseDropdownRouteSwitcher,
    KebabMenuIcon,
    DeleteIcon,
    ChangeFolderIcon,
    BarGraphIcon,
    ChevronIcon
  },
  mixins: [clickaway],
  data () {
    const headers = [
      { label: 'Report Name', span: 4 },
      { label: 'Created By', span: 4 },
      { label: 'Last Updated', span: 3 }
    ]
    return {
      headers,
      lens: null,
      lensRoutes: [],
      currentLensRoute: null,
      expandedReportOptions: null,
      lensReports: [],
      lensId: this.$route.params.lensId || null,

      reportToDelete: null,
      loading: {
        delete: false,
        lenses: true
      },
      show: {
        createReportModal: false,
        deleteReportModal: false
      }
    }
  },
  async mounted () {
    // Get lens reports
    const res = await LensAPI.LensReport.getAll({ lens_id: this.lensId })
    this.lensReports = res.data

    // Get lens details
    try {
      const lensRes = await LensAPI.Lens.get(this.lensId)
      this.lens = lensRes.data
    } catch (e) {
      console.error('Error fetching lens details:', e)
    }

    // Get all lenses for dropdown
    try {
      const allLensesRes = await LensAPI.Lens.getAll()
      this.lensRoutes = allLensesRes.data.map(lens => ({
        name: lens.name,
        routeName: 'LensDashboardView',
        params: { lensId: lens.id },
        avatar: lens.avatar || null,
        initials: this.getInitials(lens.name)
      }))

      this.currentLensRoute = this.lensRoutes.find(route => route.params.lensId === this.lensId)
    } catch (e) {
      console.error('Error fetching all lenses:', e)
    } finally {
      this.loading.lenses = false
    }
  },
  methods: {
    navToReport (reportId) {
      this.$router.push({ name: 'LensReport', params: { lensId: this.lensId, reportId } })
    },
    navToLensView () {
      this.$router.push({ name: 'LensView' })
    },
    formatDate (dateMS) {
      const date = new Date(dateMS)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return date.toLocaleDateString('en-US', options)
    },
    handleDeleteModalView (reportId) {
      this.reportToDelete = reportId
      this.show.deleteReportModal = !!reportId
    },
    async handleReportDelete () {
      const reportId = this.reportToDelete
      this.loading.delete = true
      console.log('%c Deleting Report', 'color: pink; font-size: 14px;')
      try {
        await LensAPI.LensReport.remove(reportId)
        this.lensReports = this.lensReports.filter(report => report.id !== reportId)
      } catch (e) {
        this.$showAlert({
          type: 'error',
          message: 'Deleting report error'
        })
      } finally {
        this.loading.delete = false
        this.handleDeleteModalView()
        this.$showAlert({
          type: 'success',
          message: 'Report successfully deleted'
        })
      }
    },
    getInitials (name) {
      if (!name) return ''
      const initials = name.split(' ').map(word => word[0]).join('').toUpperCase()
      return initials.substring(0, 2) // Limit to 2 characters
    }
  }
}
</script>

<style scoped>
.option-enter-active,
.option-leave-active {
  transition: opacity 100ms ease-in-out;
}

.option-enter-from,
.option-enter,
.option-leave-to {
  opacity: 0;
}

.option-enter-to,
.option-leave-from {
  opacity: 1;
}

.option-menu-button {
  transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out;
}

.option-menu-button.active {
  background-color: #ECEFF3;
}

.option-menu-button.inactive:hover {
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.report-options {
  position: absolute;
  right: calc(100% + 4px);
  top: 50%;
  transform: translateY(-50%);
  width: 105px;
  z-index: 50;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}

</style>
